<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            text="P"
            variant="light-primary"
            size="104px"
            rounded
          />
          <div
            class="d-flex flex-column ml-1"
          >
            <div class="mb-1">
              <h4 class="mb-0">
                {{ nombre }}
              </h4>
              <span class="card-text">{{ eps }}</span>
            </div>

            <EditarPaciente
              :id_paciente="id_paciente_capturado"
              @capturarDataActualizada="capturarDataActualizada"
            />
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="17"
              />
            </b-avatar>
            <div class="ml-1">
              <h6 class="mb-0">
                {{ regimen }}
              </h6>
              <small>Régimen</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="17"
              />
            </b-avatar>
            <div class="ml-1">
              <h6 class="mb-0">
                {{ tipo }}
              </h6>
              <small>Tipo</small>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="17"
              />
            </b-avatar>
            <div class="ml-1">
              <h6 class="mb-0 text-uppercase">
                {{ categoria }}
              </h6>
              <small>Categoría</small>
            </div>
          </div>
        </div>
      </b-col>
      <p />
      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Sexo</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ sexo }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ArrowDownLeftIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Dirección</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ direccion }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Teléfono</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ telefono }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="UserPlusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Fecha Nacimiento</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ fecha_nacimiento }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import EditarPaciente from './validarcrearPaciente/EditarPaciente.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    EditarPaciente,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    id_paciente_capturado: Number,
    // eslint-disable-next-line vue/require-default-prop
    nombre: String,
    // eslint-disable-next-line vue/require-default-prop
    eps: String,
    // eslint-disable-next-line vue/require-default-prop
    tipo: String,
    // eslint-disable-next-line vue/require-default-prop
    categoria: String,
    // eslint-disable-next-line vue/require-default-prop
    sexo: String,
    // eslint-disable-next-line vue/require-default-prop
    email: String,
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    fecha_nacimiento: String,
    // eslint-disable-next-line vue/require-default-prop
    direccion: String,
    // eslint-disable-next-line vue/require-default-prop
    telefono: String,
    // eslint-disable-next-line vue/require-default-prop
    regimen: String,
  },
  data() {
    return {
      dataPacienteEditar: {},
    }
  },
  methods: {
    capturarDataActualizada(data) {
      // this.$refs.EditarPaciente.prueba('Luis')
      this.eps_lista = [{ value: 1, text: 'ALIANSALUD' }, { value: 2, text: 'AXA COLPATRIA' }, { value: 3, text: 'COLMEDICA MEDICINA PREPAGADA' }, { value: 4, text: 'COLSANITAS' }, { value: 5, text: 'COMPENSAR' }, { value: 6, text: 'COOMEVA' }, { value: 7, text: 'COOSALUD' }, { value: 8, text: 'FOSCAL' }, { value: 9, text: 'NUEVA EPS' }, { value: 10, text: 'POLICIA' }, { value: 11, text: 'POSITIVA' }, { value: 12, text: 'SANITAS' }, { value: 13, text: 'SEGUROS BOLIVAR' }, { value: 14, text: 'SOCIEDAD INTEGRAL DE ESPECIALISTAS' }, { value: 15, text: 'SOULMEDICAL' }, { value: 16, text: 'SURA' }, { value: 17, text: 'UNIDAD ADMINISTRATIVA ESPECIAL DE SALUD' }, { value: 18, text: 'UNIVERSIDAD NACIONAL DE COLOMBIA' }]
      this.epsValidar = data.entidad_id - 1
      // console.log(this.eps_lista[this.epsValidar], '99999', data.entidad_id)

      this.nombre = `${data.primer_nombre} ${data.segundo_nombre} ${data.primer_apellido} ${data.segundo_apellido}`
      this.eps = this.eps_lista[this.epsValidar].text
      this.tipo = data.tipo_beneficiario
      this.categoria = data.categoria_contributivo
      this.sexo = data.sexo
      this.email = data.email
      this.fecha_nacimiento = data.fecha_nacimiento
      this.direccion = data.direccion
      this.telefono = data.celular
      this.regimen = data.tipo_regimen
      // console.log(data, '8888888888888888888888888')
      // this.primer_nombre = data.primer_nombre
    },
  },

}
</script>

<style>

</style>
