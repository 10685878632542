<template>
  <div>
    <template>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <buscar-paciente
            @recibirDataHijo="recibirDataHijo"
          />
        </b-col>
        <b-col
          v-if="documento"
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <informacion-usuario
            :id_paciente_capturado="id"
            :nombre="nombrePaciente"
            :eps="eps"
            :tipo="tipoBeneficiario"
            :categoria="categoria"
            :sexo="sexo"
            :email="email"
            :direccion="direccion"
            :telefono="telefono"
            :fecha_nacimiento="fecha_nacimiento"
            :regimen="regimen"
          />
        </b-col>
        <b-col
          v-else-if="!documento"
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <ValidarCrearPaciente />
        </b-col>

      </b-row>
      <b-row>
        <b-col
          v-if="documento"
          cols="12"
          md="5"
          xl="4"
          lg="4"
        >
          <ingresar-procedimiento @recibirDataHijoCupCie10="recibirDataHijoCupCie10" />
        </b-col>
        <b-col
          cols="12"
          xl="8"
          lg="8"
          md="7"
        >
          <tabla-registros
            :items="dataItems"
            :mensaje="mensajeValidado"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import BuscarPaciente from '@/assets/componentes/lineaFrente/BuscarPaciente.vue'
import InformacionUsuario from '@/assets/componentes/lineaFrente/InformacionUsuario.vue'
import IngresarProcedimiento from '@/assets/componentes/lineaFrente/ingresarProcedimiento/IngresarProcedimiento.vue'
import TablaRegistros from '@/assets/componentes/lineaFrente/tablaregistros/tablaregistros.vue'
import Ripple from 'vue-ripple-directive'
import ValidarCrearPaciente from '../assets/componentes/lineaFrente/validarcrearPaciente/ValidarCrearPaciente.vue'

export default {
  directives: {
    Ripple,
  },

  components:
  {
    BRow,
    BCol,
    BuscarPaciente,
    InformacionUsuario,
    IngresarProcedimiento,
    TablaRegistros,
    ValidarCrearPaciente,
  },
  data: () => ({
    documento: '',
    dataItems: [],
    prioridad: '',
    lectura: '',
    mensajeValidado: '',
  }),
  computed: {
    currentUser() {
      return this.$store.state.login.user
    },
  },
  methods: {
    recibirDataHijo(data) {
      // console.log(data,'1111111')
      this.id = data.id
      this.documento = data.documento
      this.nombrePaciente = data.nombre
      this.eps = data.eps
      this.sexo = data.sexo
      this.email = data.email
      this.fecha_nacimiento = data.fecha_nacimiento
      this.direccion = data.direccion
      this.telefono = data.telefono
      this.tipoBeneficiario = data.tipo_beneficiario
      this.categoria = data.categoria
      this.regimen = data.regimen
      // eslint-disable-next-line no-restricted-globals
      this.mostrarDataTabla()
    },

    recibirDataHijoCupCie10(data, prioridadExamen, lecturaExamen, nombreProfesional) {
      // console.log(this.correo,'ooooooooooooooo')
      this.mensajeValidado = `MYTSALUD le informa que el codigo para ver su resultado de imagenes diagnosticas es ${data.codigo}`
      this.dataUsuarioMongo = {
        id_paciente: this.id,
        nombre_cup: data.nombre_cup,
        documento: this.documento,
        codigo_cup: data.cup,
        codigo_cie10: data.cie10,
        tipo_procedimiento: 'RADIOGRAFIA',
        codigo_resultado: data.codigo,
        nombre_paciente: this.nombrePaciente,
        eps: this.eps,
        telefono: this.telefono,
        nombre_linea_frente: this.currentUser.nombre,
        estado: false,
        resultado: '',
        mensaje_enviado: false,
        prioridad: prioridadExamen,
        lectura: lecturaExamen,
        mensaje: this.mensajeValidado,
        id_profesional: nombreProfesional,
        email: this.email
      }
      // eslint-disable-next-line no-unused-expressions, no-sequences
      if (data.estado_cup) this.$store.dispatch('apiFast/guardarPaciente', this.dataUsuarioMongo).then(response => response), this.mostrarDataTabla()
    },
    mostrarDataTabla() {
      this.$store.dispatch('apiFast/tablaRegistro').then(response => {
        // console.log(response.data, 'veeeeeeeeeeeeeeeeeeeeeeeeeeeee')
        this.dataItems = response.data
      })
    },
    // capturarDataActualizada(data) {
    //   console.log(data, '8888888888888888888888888')
    //   // this.primer_nombre = data.primer_nombre
    // <select-multiple
    //       :options="data_prueba"
    //     />
    // },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
