<template>
  <b-card-code
    title="Ingresar Procedimiento"
  >
    <b-form @submit.prevent>

      <b-row>

        <b-col cols="12">
          <input
            id="checkbox-prioridad"
            v-model="prioritario"

            type="checkbox"
            true-value="prioritario"
            false-value="no prioritario"
          >
          Prioritario
        </b-col>

        <b-col cols="12">
          <input
            id="checkbox-prioridad"
            v-model="lectura"
            type="checkbox"
            true-value="con lectura"
            false-value="sin lectura"
          >
            Con lectura
          <p />
        </b-col>
      </b-row>

      <b-row>
        <!-- codigo cup -->
        <b-col cols="12">
          <b-form-group
          >
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="this.$store.state.listaProfesional"
            />
          </b-form-group>
          <!-- danger -->
          <b-alert
              v-if="validarAlerta"
              variant="danger"
              show
            >
              <div class="alert-body">
                Se debe seleccionar un profesional :)
              </div>
            </b-alert>
        </b-col>

        <!-- Código procedimiento -->
        <b-col cols="12">
          <b-form-group
            label="Código procedimiento"
            label-for="documento-usuario"
          >
            <b-form-input
              id="basicInput"
              v-model="cup_id"
              placeholder="Código procedimiento"
            />
            <!-- danger -->
            <b-alert
              v-if="alerta.cup === false"
              variant="danger"
              show
            >
              <div class="alert-body">
                Verificar el cup :)
              </div>
            </b-alert>
          </b-form-group>
        </b-col>

        <!-- Diagnóstico -->
        <b-col cols="12">
          <b-form-group
            label="Diagnóstico"
            label-for="documento-usuario"
          >
            <b-form-input
              id="basicInput"
              v-model="diagnostico_id"
              placeholder="Código diagnóstico CIE10"
            />
            <!-- danger -->
            <b-alert
              v-if="alerta.cie10 === false"
              variant="danger"
              show
            >
              <div class="alert-body">
                Verificar el cie10 :)
              </div>
            </b-alert>
          </b-form-group>

        </b-col>

        <!-- Código resultado -->
        <b-col cols="12">
          <b-form-group

            label="Código resultado"
            label-for="codigo-resultado"
          >
            <b-form-input
              id="codigo-resultado"
              v-model="codigo"
              placeholder="Código de resultado"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <div>
            <VueHtml2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="myPDF"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a8"
              pdf-orientation="landscape"
              pdf-content-width="800px"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <b-col
                  md="6"
                  lg="4"
                >
                  <h6 
                    class="text-center">
                    <img src="@/assets/images/logo/logomyt.png"  width="50" height="25" />
                    MYT
                  </h6>
                  <h6 
                    class="text-center"
                  >
                    Su codigo de resultado es {{ codigo }}, consulta tus resultados en https://img.mytsalud.com
                  </h6>
                </b-col>
              </section>
            </VueHtml2pdf>
          </div>

          <b-form-group
            label-for="boton-generar"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="validarCuoCie10(cup_id, diagnostico_id)"
            >
              Cargar Procedimiento
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-login
              variant="primary"
              @click="formularioCrearProfesional()"
            >
              Crear Profesional
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <template #code>
      {{ codeVertical }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BAlert, BFormSelect, BCardHeader,
  BBadge, BCardText, BModal, VBModal
} from 'bootstrap-vue'
import VueHtml2pdf from 'vue-html2pdf'
import Ripple from 'vue-ripple-directive'
import { codeVertical } from './code'
import vSelect from 'vue-select'
import Prueba from '../crearProfesional/prueba.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BModal,
    VBModal,
    Prueba,
    BCardCode,
    VueHtml2pdf,
    BBadge,
    BCardText,
    vSelect,
    BCardHeader,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BAlert,
    BFormSelect,
    ValidationProvider,  ValidationObserver
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      tipo_identificacion: '',
      tipo_identificacion_lista:[
        {
          value:'CE',
          text:'Cédula de extranjería'
        },
        {
          value:'CC',
          text:'Cédula ciudadanía'
        },
        {
          value:'CD',
          text:'Carné diplomático'
        },
      ],
      tipo_especialidades:[
        {
          value:'MEDICINA GENERAL',
          text:'MEDICINA GENERAL'
        },
        {
          value:'NUTRICIÓN Y DIETÉTICA',
          text:'NUTRICIÓN Y DIETÉTICA'
        },
        {
          value:'ENFERMERÍA',
          text:'ENFERMERÍA'
        },
        {
          value:'PSICOLOGÍA',
          text:'PSICOLOGÍA'
        },
        {
          value:'TRABAJO SOCIAL',
          text:'TRABAJO SOCIAL'
        },
        {
          value:'FONOAUDIOLOGÍA',
          text:'FONOAUDIOLOGÍA'
        },
        {
          value:'FISIOTERAPIA',
          text:'FISIOTERAPIA'
        },
        {
          value:'TERAPIA RESPIRATORIA',
          text:'TERAPIA RESPIRATORIA'
        },
        {
          value:'TERAPIA OCUPACIONAL',
          text:'TERAPIA OCUPACIONAL'
        },
        {
          value:'CIRUGÍA GENERAL',
          text:'CIRUGÍA GENERAL'
        },
        {
          value:'DERMATOLOGÍA',
          text:'DERMATOLOGÍA'
        },
        {
          value:'GINECOLOGÍA Y OBSTETRICIA',
          text:'GINECOLOGÍA Y OBSTETRICIA'
        },
        {
          value:'INFECTOLOGÍA',
          text:'INFECTOLOGÍA'
        },
        {
          value:'MEDICINA FÍSICA Y REHABILITACIÓN',
          text:'MEDICINA FÍSICA Y REHABILITACIÓN'
        },
        {
          value:'MEDICINA INTERNA',
          text:'MEDICINA INTERNA'
        },
        {
          value:'ORTOPEDIA Y TRAUMATOLOGÍA',
          text:'ORTOPEDIA Y TRAUMATOLOGÍA'
        },
        {
          value:'OTORRINOLARINGOLOGÍA',
          text:'OTORRINOLARINGOLOGÍA'
        },
        {
          value:'PEDIATRÍA',
          text:'PEDIATRÍA'
        },
        {
          value:'NEUROLOGÍA',
          text:'NEUROLOGÍA'
        },
        {
          value:'NEUMOLOGÍA',
          text:'NEUMOLOGÍA'
        },
        {
          value:'NEUROLOGÍA PEDIÁTRICA',
          text:'NEUROLOGÍA PEDIÁTRICA'
        },
        {
          value:'PSIQUIATRÍA',
          text:'PSIQUIATRÍA'
        },
        {
          value:'UROLOGÍA',
          text:'UROLOGÍA'
        },
        {
          value:'QUÍMICO FARMACÉUTICO',
          text:'QUÍMICO FARMACÉUTICO'
        },
      ],
      tipo_procedimiento:[
        {
          value:'LABORATORIO',
          text:'LABORATORIO'
        },
        {
          value:'IMAGENOLOGIA',
          text:'IMAGENOLOGIA'
        },
      ],
      tipo_sedes:[
        {
          value:'TAME PRINCIPAL',
          text:'TAME PRINCIPAL'
        },
        {
          value:'ARAUQUITA PRINCIPAL',
          text:'ARAUQUITA PRINCIPAL'
        },
        {
          value:'ARAUCA PRINCIPAL',
          text:'ARAUCA PRINCIPAL'
        },
        {
          value:'SARAVENA PRINCIPAL',
          text:'SARAVENA PRINCIPAL'
        },
        {
          value:'YOPAL PRINCIPAL',
          text:'YOPAL PRINCIPAL'
        },
      ],
      especialidad: '',
      numero_identificacion: '',
      tarjetaProfesional: '',
      procedimiento:'',
      lista_profesional: this.$store.state.listaProfesional,
      email:'',
      sede:'',
      cup_id: '',
      codeVertical,
      mostrarFormulario: false,
      diagnostico_id: '',
      codigo: '',
      alerta: '',
      prioritario: 'no prioritario',
      lectura: 'sin lectura',
      selected: { title: 'Seleccione al profesional de la salud' },
      validarAlerta:'',
    }
  },

  methods: {

    // eslint-disable-next-line camelcase
    validarCuoCie10(cup_id, diagnostico_id) {
      this.dataCup = {
        cup: cup_id,
        diagnostico: diagnostico_id.toUpperCase(),
      }

      this.$store.dispatch('apiFast/validarCup', this.dataCup).then(response => {
        
        if (this.selected.title === 'Seleccione al profesional de la salud') {
          this.validarAlerta = true
          // console.log(this.selected.title,'888888888888888')
        } else {
          if (response.data.estado_cup) {
            if (response.data.estado_cie10) {
              this.$emit('recibirDataHijoCupCie10', response.data, this.prioritario, this.lectura, String(this.selected.title))
              this.codigo = response.data.codigo, this.cup_id = '', this.diagnostico_id = ''
              this.selected =  { title: 'Seleccione al profesional de la salud' }
              this.generateReport()
            }
          }
          this.alerta = response.data
          this.prioritario = 'no prioritario'
          this.lectura = 'sin lectura'
          this.validarAlerta = false
        }
      })
    },

    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },


    formularioCrearProfesional () {
      this.$router.push({ name: 'crear-profesional' })
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
