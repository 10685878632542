<template>

  <div>
    <div class="d-flex flex-wrap">
      <b-button
        v-b-modal.modal-editar-paciente
        variant="outline-secondary"
        @click="editarPaciente"
      >
        Editar
      </b-button>
    </div>

    <!-- modal editar paciente-->
    <b-modal
      id="modal-editar-paciente"
      size="xl"
      cancel-variant="outline-secondary"
      title="Editar Usuario"
      centered
      cancel-title="Cancelar"
      ok-title="Editar Paciente"
      @ok="guardarDataEditada()"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="3">
              <!-- Campo primer Nombre, si es input abajo se retorna vacio, si es select, se retorna vacio y se crea una lista-->
              <b-form-group
                label="Primer Nombre"
                label-for="mc-Primer-Nombre"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Primer Nombre"
                  rules="required|alpha"
                >
                  <b-form-input
                    id="mc-Primer-Nombre"
                    v-model="primer_nombre"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Primer Nombre"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- segundo item, cada item se encuentra entre las etiquetas col -->
            <b-col md="3">
              <b-form-group
                label="Segundo Nombre"
                label-for="mc-Segundo-Nombre"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Segundo Nombre"
                  rules="alpha"
                >
                  <b-form-input
                    id="mc-Segundo-Nombre"
                    v-model="segundo_nombre"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Segundo Nombre"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- primer apellido -->
            <b-col md="3">
              <b-form-group
                label="Primer Apellido"
                label-for="mc-Primer-Apellido"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Primer Apellido"
                  rules="required|alpha"
                >
                  <b-form-input
                    id="mc-Primer-Apellido"
                    v-model="primer_apellido"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Primer Apellido"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- segundo apellido -->
            <b-col md="3">
              <b-form-group
                label="Segundo Apellido"
                label-for="mc-Segundo-Apellido"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Primer Apellido"
                  rules="alpha"
                >
                  <b-form-input
                    id="mc-Segundo-Apellido"
                    v-model="segundo_apellido"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Segundo Apellido"
                  />
                </validation-provider>
              </b-form-group>
            <!-- eps -->
            </b-col>
            <b-col md="3">
              <b-form-group
                label="EPS del usuario"
                label-for="mc-eps"
              >
                <v-select
                  v-model="eps"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="eps_lista"
                  :reduce=" text=> text.value"
                />
              </b-form-group>
            </b-col>
            <!-- tipo de documento -->
            <b-col md="3">
              <b-form-group
                label="Tipo de identificacion"
                label-for="mc-tipo-identificacion"
              >
                <v-select
                  v-model="tipo_identificacion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="tipo_identificacion_lista"
                  :reduce=" text=> text.value"
                />
              </b-form-group>
            </b-col>
            <!-- numero de documento -->
            <b-col md="3">
              <b-form-group
                label="Numero identificacion"
                label-for="mc-numero-identificacion"
              >
                <validation-provider
                  #default="{ errors }"
                  name="numero identificacion"
                  rules="required|min:3|integer"
                >
                  <b-form-input
                    id="mc-numero-identificacion"
                    v-model="numero_identificacion"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Numero identificacion"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- regimen -->
            <b-col md="3">
              <b-form-group
                label="Régimen"
                label-for="mc-regimen"
              >
                <v-select
                  v-model="regimen"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="Régimen"
                  :options="regimen_lista"
                />
              </b-form-group>
            </b-col>
            <!-- tipo de regimen, cambia las opciones del select dependiendo si es cont o sub -->
            <b-col
              v-if="regimen==='Contributivo' || regimen==='Subsidiado'"
              md="3"
            >
              <b-form-group
                label="Tipo Régimen"
                label-for="mc-tipo-regimen"
              >
                <v-select
                  v-model="tipo_regimen"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="tipo_regimen_lista[regimen]"
                />
              </b-form-group>
            </b-col>
            <!-- categoria -->
            <b-col
              v-if="regimen==='Contributivo'"
              md="3"
            >

              <b-form-group
                label="Categoría"
                label-for="mc-categoria"
              >
                <v-select
                  v-model="categoria"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="categoria_lista"
                />
              </b-form-group>
            </b-col>

            <!-- relacion con el titular cuando no es cotizante o cabeza de fam-->
            <b-col
              v-if="tipo_regimen==='BENEFICIARIO' || tipo_regimen==='ADICION'"
              md="3"
            >
              <b-form-group
                label="Relacion Con El Titular"
                label-for="mc-relacion"
              >
                <v-select
                  v-model="relacion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="Relacion Con El Titular"
                  :options="relacion_lista"
                />
              </b-form-group>
            </b-col>
            <!-- numero de documento del cotizante-->
            <b-col
              v-if="regimen==='Contributivo' && tipo_regimen==='BENEFICIARIO'"
              md="3"
            >

              <b-form-group
                label="Numero Documento cotizante"
                label-for="mc-numero-identificacion-cotizante"
              >
                <validation-provider
                  #default="{ errors }"
                  name="numero identificacion cotizante"
                  rules="required|min:3|integer"
                >
                  <b-form-input
                    id="mc-numero-identificacion-cotizante"
                    v-model="numero_identificacion_cotizante"
                    :state="errors.length > 0 ? false:null"
                    placeholder="numero identificacion cotizante"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- nivel de sisben -->
            <b-col
              v-if="regimen==='Subsidiado'"
              md="3"
            >
              <b-form-group
                label="Nivel Sisben"
                label-for="mc-nivel-sisben"
              >
                <v-select
                  v-model="nivel_sisben"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nivel sisben"
                  :options="sisben_lista"
                />
              </b-form-group>
            </b-col>
            <!-- fecha de nacimiento -->
            <b-col md="3">
              <label for="example-input">Fecha de Nacimiento</label>
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="fecha_nacimiento"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="fecha_nacimiento"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="es"
                    aria-controls="example-input"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <!-- sexo -->
            <b-col md="3">
              <b-form-group
                label="Sexo"
                label-for="mc-sexi"
              >
                <v-select
                  v-model="sexo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="Sexo"
                  :options="sexo_lista"
                />
              </b-form-group>
            </b-col>
            <!-- zona_residencial -->
            <b-col md="3">
              <b-form-group
                label="Zona Residencial"
                label-for="mc-zona-residencial"
              >
                <v-select
                  v-model="zona_residencial"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="zona_residencial_lista"
                  :reduce=" text=> text.value"
                />
              </b-form-group>
            </b-col>
            <!-- Departamento -->
            <b-col md="3">
              <b-form-group
                label="departamento"
                label-for="mc-departamento"
              >
                <v-select
                  id="mc-departamento"
                  v-model="departamento"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="departamento_lista"
                  :reduce=" text=> text.value"
                />
              </b-form-group>
            </b-col>
            <!-- municipio -->
            <b-col md="3">
              <b-form-group
                label="municipio"
                label-for="mc-municipio"
              >
                <v-select
                  id="mc-municipio"
                  v-model="municipio"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="municipio_lista"
                  :reduce=" text=> text.value"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="direccion"
                label-for="direccion"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dirección"
                  rules="required"
                >
                  <b-form-input
                    id="mc-direccion"
                    v-model="direccion"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Direccion"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Estado Civil"
                label-for="mc-estado-civil"
              >
                <v-select
                  id="mc-estado-civil"
                  v-model="estado_civil"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="estado_civil_lista"
                  :reduce=" text=> text.value"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Telefono"
                label-for="telefono"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telefono"
                  rules="required|digits:10"
                >
                  <b-form-input
                    id="mc-Telefono"
                    v-model="telefono"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Telefono Celular sin puntos ni espacios"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Nivel Educativo"
                label-for="mc-nivel-educativo"
              >
                <v-select
                  id="mc-Nivel-Educativo"
                  v-model="nivel_educativo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="Nivel Educativo"
                  :options="nivel_educativo_lista"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="mc-email"
                label="Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="mc-email"
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="mc-pertenencia-etnica"
                label="Pertenencia Étnica"
              >
                <v-select
                  id="mc-pertenencia-etnica"
                  v-model="etnia"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="Pertenencia Étnica"
                  :options="etnia_lista"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BInputGroup, BFormInput, BForm, BButton, BFormDatepicker, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

export default {
  components: {
    BInputGroupAppend,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    id_paciente: Number,
  },
  data() {
    return {
      data_paciente_editar: {},
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      eps: '',
      estado: 'ACTIVO',
      exento_copago: false,
      tipo_identificacion: '',
      numero_identificacion: '',
      regimen: '',
      tipo_regimen: '',
      relacion: '',
      numero_identificacion_cotizante: '',
      categoria: '',
      nivel_sisben: '1',
      fecha_nacimiento: '',
      sexo: '',
      zona_residencial: '',
      departamento: '',
      municipio: '',
      direccion: '',
      estado_civil: '',
      telefono: '',
      nivel_educativo: '',
      email: '',
      etnia: '',
      eps_lista: [{ value: 1, text: 'ALIANSALUD' }, { value: 2, text: 'AXA COLPATRIA' }, { value: 3, text: 'COLMEDICA MEDICINA PREPAGADA' }, { value: 4, text: 'COLSANITAS' }, { value: 5, text: 'COMPENSAR' }, { value: 6, text: 'COOMEVA' }, { value: 7, text: 'COOSALUD' }, { value: 8, text: 'FOSCAL' }, { value: 9, text: 'NUEVA EPS' }, { value: 10, text: 'POLICIA' }, { value: 11, text: 'POSITIVA' }, { value: 12, text: 'SANITAS' }, { value: 13, text: 'SEGUROS BOLIVAR' }, { value: 14, text: 'SOCIEDAD INTEGRAL DE ESPECIALISTAS' }, { value: 15, text: 'SOULMEDICAL' }, { value: 16, text: 'SURA' }, { value: 17, text: 'UNIDAD ADMINISTRATIVA ESPECIAL DE SALUD' }, { value: 18, text: 'UNIVERSIDAD NACIONAL DE COLOMBIA' }],
      tipo_identificacion_lista: [{ value: 'CE', text: 'Cédula de extranjería' }, { value: 'CC', text: 'Cédula ciudadanía' }, { value: 'CD', text: 'Carné diplomático' }, { value: 'PA', text: 'Pasaporte' }, { value: 'SC', text: 'Salvoconducto' }, { value: 'PE', text: 'Permiso Especial de Permanencia' }, { value: 'RC', text: 'Registro civil' }, { value: 'TI', text: 'Tarjeta de identidad' }, { value: 'CN', text: 'Certificado de nacido vivo' }, { value: 'AS', text: 'Adulto sin identificar' }, { value: 'MS', text: 'Menor sin identificar' }, { value: 'PT', text: 'Permiso Por Protección Temporal' }],
      regimen_lista: [
        'Contributivo',
        'Subsidiado',
        'Vinculado',
        'Particular',
        'Víctima Régimen Contributivo',
        'Víctima Régimen subsidiado',
        'Víctima no asegurado (Vinculado)',
        'Otro',
      ],
      tipo_regimen_lista: { Contributivo: ['COTIZANTE', 'BENEFICIARIO', 'ADICION'], Subsidiado: ['CABEZA DE FAMILIA', 'BENEFICIARIO', 'ADICION'] },
      categoria_lista: ['A', 'B', 'C'],
      relacion_lista: ['Padre/Madre', 'Hijo(a)', 'Conyugue-Compañero(a)', 'Otro'],
      sisben_lista: ['1', '2', '3'],
      sexo_lista: ['Masculino', 'Femenino'],
      zona_residencial_lista: [{ value: 'U', text: 'Urbana' }, { value: 'R', text: 'Rural' }],
      estado_civil_lista: [
        { value: 'SOLTER@', text: 'Soltero(a)' },
        { value: 'CASAD@', text: 'Casado(a)' },
        { value: 'UNION LIBRE', text: 'Union Libre' },
        { value: 'SEPARAD@', text: 'Separado(a)' },
        { value: 'VIUD@', text: 'viudo(a)' },
      ],
      nivel_educativo_lista: ['Preescolar', 'Básica Primaria', 'Básica Secundaria',
        'Media Técnica', 'Normalista', 'Técnica Profesional', 'Tecnológica', 'Profesional', 'Especialización', 'Maestría', 'Doctorado', 'Ninguno'],
      etnia_lista: ['Otras etnias', 'Indigena', 'ROM (Gitanos)', 'Raizal(San Andres y Providencia)', 'Palenquero de San Basilio', 'Negro(a), Mulato(a), Afrocolombiano(a) o Afrodescendiente', 'Blanca', 'Mestiza'],
      departamento_lista: [{ value: 81, text: 'Arauca' }],
      municipio_lista: [{ value: 137, text: 'Arauca' }, { value: 138, text: 'Arauquita' }, { value: 139, text: 'Cravo Norte' }, { value: 140, text: 'Fortul' }, { value: 141, text: 'Puerto Rondon' }, { value: 142, text: 'Saravena' }, { value: 143, text: 'Tame' }],
    }
  },
  methods: {

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
    guardarDataEditada() {
      // eslint-disable-next-line no-bitwise
      if (!this.segundo_apellido) this.segundo_apellido = ''
      if (!this.segundo_nombre) this.segundo_nombre = ''
      this.dataPaciente = {
        categoria_contributivo: this.categoria,
        cedula_cotizante: '',
        celular: this.telefono,
        ciiu_id: '',
        direccion: this.direccion,
        email: this.email,
        entidad_id: this.eps,
        estado: 'ACTIVO',
        estado_civil: this.estado_civil,
        exento_copago: false,
        fecha_nacimiento: this.fecha_nacimiento,
        identificacion: this.numero_identificacion,
        departamento: this.departamento,
        municipio_id: this.municipio,
        nivel_educativo: this.nivel_educativo,
        nivel_sisben: this.nivel_sisben,
        pertenencia_etnica: this.etnia,
        primer_apellido: this.primer_apellido,
        primer_nombre: this.primer_nombre,
        relacion_beneficiario: '',
        segundo_apellido: this.segundo_apellido,
        segundo_nombre: this.segundo_nombre,
        sexo: this.sexo,
        tipo_beneficiario: this.tipo_regimen,
        tipo_identificacion: this.tipo_identificacion,
        tipo_regimen: this.regimen,
        zona_residencial: this.zona_residencial,
      }
      this.$emit('capturarDataActualizada', this.dataPaciente)

      // console.log(this.dataPaciente, 'validarrrrrrrrrrrrrrrrrrr')
      this.$store.dispatch('apiFast/guardarPacienteEditado', this.dataPaciente).then(response => {
        // console.log('data a editar')
        this.dataPacienteEditar = response.data
        // console.log(response.data)
      })
    },
    editarPaciente() {
      // console.log(this.data_paciente_editar, 'esteeeeeeeee')
      this.dataIdPaciente = {
        id: this.id_paciente,
      }
      this.$store.dispatch('apiFast/editarPaciente', this.dataIdPaciente).then(response => {
        // console.log('data a editar')

        this.informacionPacienteEditar = response.data
        // console.log(this.informacionPacienteEditar, 'aquiiiiiiiiiiiiiiiiiiii')
        this.primer_nombre = this.informacionPacienteEditar.primer_nombre
        this.segundo_nombre = this.informacionPacienteEditar.segundo_nombre
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.primer_apellido = this.informacionPacienteEditar.primer_apellido
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.segundo_apellido = this.informacionPacienteEditar.segundo_apellido
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.eps = this.informacionPacienteEditar.eps
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.tipo_identificacion = this.informacionPacienteEditar.tipo_identificacion
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.numero_identificacion = this.informacionPacienteEditar.identificacion
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.regimen = this.informacionPacienteEditar.regimen
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.tipo_regimen = this.informacionPacienteEditar.tipo_regimen
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.categoria = this.informacionPacienteEditar.categoria_contributivo
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.fecha_nacimiento = this.informacionPacienteEditar.fecha_nacimiento
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sexo = this.informacionPacienteEditar.sexo
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.zona_residencial = this.informacionPacienteEditar.zona_residencial
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.departamento = this.informacionPacienteEditar.departamento_id
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.municipio = this.informacionPacienteEditar.municipio_id
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.direccion = this.informacionPacienteEditar.direccion
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.estado_civil = this.informacionPacienteEditar.estado_civil
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.telefono = this.informacionPacienteEditar.celular
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.nivel_educativo = this.informacionPacienteEditar.nivel_educativo
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.nivel_sisben = this.informacionPacienteEditar.nivel_sisben
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.email = this.informacionPacienteEditar.email
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.etnia = this.informacionPacienteEditar.pertenencia_etnica
      })
    },
  },
}

</script>
