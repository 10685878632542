<template>
	<b-card
	  no-body
	>
	  <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
		<h5 class="mb-0">
		  Crear Paciente
		</h5>
		<b-badge variant="light-primary">
		  Myt
		</b-badge>
		<small class="text-muted w-100" />
	  </b-card-header>
	  <b-card-body>
		<center>
		<ul class="list-unstyled my-1">
		  <li>
			<span class="align-middle">Si al buscar el documento</span>
		  </li>
		  <li class="my-25">
			<span class="align-middle">este cuadro <strong> no</strong> cambia,</span>
		  </li>
		  <li>
			<span class="align-middle">debe crear el paciente</span>
		  </li>	  
		</ul>
		</center>
		<br>
		<div class="d-flex flex-sm-row flex-column flex-wrap justify-content-center">
			<CrearPaciente/></div>
	  </b-card-body>
	  <!-- modal login-->
	</b-card>
	
  </template>
  
  <script>
  import {
	BCard, BCardHeader, BCardBody, BBadge, BButton, BFormInput, BFormGroup,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
import CrearPaciente from './CrearPaciente.vue';

  
  export default {
	directives: {
	  Ripple,
	},
  
	components: {
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    CrearPaciente
},

}

  </script>
  
  <style>
  
  </style>
  