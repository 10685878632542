<template>
  <b-card
    no-body
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Buscar Paciente
      </h5>
      <b-badge variant="light-primary">
        Myt
      </b-badge>
      <small class="text-muted w-100" />
    </b-card-header>

    <b-card-body>
      <b-form-group
        label=" "
        label-for="documento-usuario"
      >
        <br>
        <label>Escriba el N° documento</label>
        <b-form-input
          id="cedula linea"
          v-model="documento"
          type="number"
          placeholder="Documento usuario"
        />
      </b-form-group>

      <br>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="enviarDataPadre()"
      >
        Buscar
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },

  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  data() {
    return {
      documento: '',
    }
  },
  methods: {
    enviarDataPadre() {
      this.documentoPaciente = {
        documento: this.documento,
      }

      this.$store.dispatch('apiFast/listarProfesionalSalud').then(response => {
        // console.log(response.data)
        this.$store.state.listaProfesional = response.data
      })

      this.$store.dispatch('apiFast/validarPaciente', this.documentoPaciente).then(response => {
        // console.log('datos correooooo')
        // se recibe la data de la api, validar formulario creaar
        this.$emit('recibirDataHijo', response.data)
        this.documento = ''
      })
    },
  },
}
</script>

<style>

</style>
