<template>
  <b-card-code
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Ordenar"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="3"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  ninguno
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filtro"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Escriba aquí "
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :small="small"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(acciones)="data">
        <b-button
          :id="`borrar-${data.item.codigo}`"
          target="_blank"
          class="btn-icon rounded-circle"
          variant="flat-secondary"
          @click="confirmText(data.item.codigo)"
        >
          <feather-icon
            icon="Trash2Icon"
            size="20"
          />
        </b-button>

        <b-tooltip
          :target="`borrar-${data.item.codigo}`"
          placement="left"
        >
          <p class="mb-0">
            Click Para Borrar el registro
          </p>

        </b-tooltip>

      </template>
      <template #cell(status)="row">

        <b-button
          :id="`invoice-row-${row.item.codigo}`"
          target="_blank"
          class="btn-icon rounded-circle"
          :variant="status[0][row.item.mensaje_enviado]"
          @click="enviarMensajeLineaFrente(row.item.telefono, row.item.codigo)"
        >
          <feather-icon
            :icon="status[1][row.item.mensaje_enviado]"
            size="20"
          />
        </b-button>
        <!-- tooltip cuando se envía mensaje -->
        <b-tooltip
          v-if="row.item.mensaje_enviado === true"
          :target="`invoice-row-${row.item.codigo}`"
          placement="left"
        >
          <p class="mb-0">
            El codigo ya fué enviado
          </p>

        </b-tooltip>
        <!-- tooltip sin resultado -->
        <b-tooltip
          v-else
          :target="`invoice-row-${row.item.codigo}`"
          placement="left"
        >
          <p class="mb-0">
            No has enviado el codigo :(
          </p>

        </b-tooltip>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        :small="small"
        label="ítems por página"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <template #code>
      {{ codeKitchenSink }}
      {{ codeConfirm }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
  BButton, BCardBody, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { codeKitchenSink } from './code'
import { codeConfirm } from './code copy'

export default {
  components: {

    BTooltip,
    BCardCode,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    items: Array,
    // eslint-disable-next-line vue/require-default-prop
    mensaje: String,
  },
  data() {
    return {
      codeConfirm,
      small: true,
      perPage: 3,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        'cie10',
        { key: 'documento', label: 'documento', sortable: true },
        'telefono',
        'codigo',
        { key: 'acciones', label: 'acciones' },
        { key: 'status', label: 'status' },
      ],

      acciones: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      status: [{
        true: 'flat-success', false: 'flat-danger',
      },
      {
        true: 'CheckCircleIcon', false: 'XCircleIcon',
      }],

      codeKitchenSink,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  methods: {

    enviarMensajeLineaFrente(numeroTelefono, codigoGenerado) {
      // console.log(numeroTelefono, 'siiiiiiii ya se envió el mensaje', codigoGenerado)
      // console.log(this.items)
      // this.tipoExamen()
      this.dataActualizar = {
        codigo: codigoGenerado,
        resultado: '',
      }
      this.$store.dispatch('apiFast/actualizarPaciente', this.dataActualizar).then(response => response)

      // `MYTSALUD le informa que el codigo para ver su resultado de imagenes diagnosticas es ${codigoGenerado} y puede consultarlo mañana en img.mytsalud.com:8000`
      // if (this.prioritario === 'prioritario') { this.mensaje = `MYTSALUD le informa que el codigo para ver su resultado de imagenes diagnosticas es ${codigoGenerado} y puede consultarlo el dia de hoy en img.mytsalud.com:8000` }
      // if (this.ConLectura === 'con lectura') { this.mensaje = `MYTSALUD le informa que el codigo para ver su resultado de imagenes diagnosticas es ${codigoGenerado} y puede consultarlo en dos dias en img.mytsalud.com:8000` } else (this.mensaje = `MYTSALUD le informa que el codigo para ver su resultado de imagenes diagnosticas es ${codigoGenerado} y puede consultarlo mañana en img.mytsalud.com:8000`)

      this.dataTelefono = {
        codigo: codigoGenerado,
        texto: '',
      }
      // console.log(this.items, '2222222222222222222222222')
      this.$store.dispatch('apiFast/enviarMensaje', this.dataTelefono).then(response => response)
      this.mostrarDataTabla()
    },

    mostrarDataTabla() {
      this.$store.dispatch('apiFast/tablaRegistro').then(response => {
        this.items = []
        // console.log(response.data, 'veeeeeeeeeeeeeeeeeeeeeeeeeeeee')
        this.items = response.data
      })
    },
    // confirm texrt
    confirmText(codigoEliminar) {
      this.$swal({
        title: '¿Estás seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, bórralo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: '¡Eliminado!',
            text: 'El registro ha sido eliminado.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          // console.log('si se elimino', codigoEliminar)
          this.dataEliminar = {
            codigo: codigoEliminar,
          }
          this.$store.dispatch('apiFast/eliminarPaciente', this.dataEliminar).then(response => response)
        }
        this.mostrarDataTabla()
      })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    // comen
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  </style>
